var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"fixed pin-t inset-x-0 z-50",class:`${_vm.scrolled ? _vm.scrolledClass : _vm.unscrolledClass}`},[_c('div',{staticClass:"container px-4 mx-auto"},[_c('div',{staticClass:"flex items-center justify-between -mx-4"},[_c('div',{staticClass:"px-4 relative z-50"},[_vm._t("logo",null,{"scrolled":_vm.scrolled,"showMobileMenu":_vm.showMobileMenu})],2),_c('div',{staticClass:"px-4 flex-1 absolute h-screen inset-x-0 top-0 z-40",class:[
                    `${_vm.mobileBreakpoint}:block ${_vm.mobileBreakpoint}:relative ${_vm.mobileBreakpoint}:h-auto`,
                    {
                        block: _vm.showMobileMenu,
                        hidden: !_vm.showMobileMenu
                    }
                ]},[_vm._t("menu",null,{"vtcClass":`absolute inset-0 ${_vm.mobileBreakpoint}:relative pt-20 ${_vm.mobileBreakpoint}:pt-0`,"showMobileMenu":_vm.showMobileMenu,"scrolled":_vm.scrolled})],2),_c('div',{staticClass:"px-4 relative z-50",class:`${_vm.mobileBreakpoint}:hidden`},[_vm._t("burger",function(){return [_c('VtcBurger',{attrs:{"color":_vm.burgerColor},model:{value:(_vm.showMobileMenu),callback:function ($$v) {_vm.showMobileMenu=$$v},expression:"showMobileMenu"}})]},{"scrolled":_vm.scrolled,"showMobileMenu":_vm.showMobileMenu})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }